import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
// import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

import dolby from "assets/img/partners/dolby.png";
import merging from "assets/img/partners/merging.jpg";
import trinnov from "assets/img/partners/trinnov.jpg";
import focal from "assets/img/partners/focal.png";
import elipson from "assets/img/partners/elipson.png";

class PartnersSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      // classes.imgRaised,
      classes.imgRounded,
      classes.imgFluid
    );
    var partners = [
      {
        img:dolby,
        name: 'Dolby Labs',
        description: 'Most widely used and efficient immersive audio technology available.',
        link: 'https://www.dolby.com/music/'
      },
      {
        img:merging,
        name: 'Merging Technologies',
        description: 'Audio interfaces and converters.',
        link: 'https://www.merging.com/'
      },
      {
        img:trinnov,
        name: 'Trinnov',
        description: 'Room correction solution.',
        link: 'https://www.trinnov.com/'
      },
      {
        img:focal,
        name: 'Focal',
        description: 'The reference monitors you see on every control room picture.',
        link: 'https://www.focal.com/en/pro-audio'
      },
      {
        img:elipson,
        name: 'Elipson',
        description: 'Legendary french speaker brand.',
        link: 'https://en.elipson.com/'
      },
    ];

    var partnersCards = partners.map(function(partner){
        return <GridItem xs={12} sm={12} md={4} key={partner.name}>
          <Card plain>
          <GridItem xs={8} sm={8} md={4} className={classes.itemGrid}>
            <img src={partner.img} alt="..." className={imageClasses} />
          </GridItem>
            <h4 className={classes.cardTitle}>{partner.name}</h4>
            <CardBody>
              <p className={classes.description}>
                {partner.description}
              </p>
              <p>Visit :{" "}<a
                className={classes.link}
                href={partner.link}
                target="_blank"
              >{partner.link}</a></p>
            </CardBody>
          </Card>
        </GridItem>;
    })

    return (
      <div className={classes.section}>
        <h2 className={classes.title}>Selected partners</h2>
        <div>
          <GridContainer>
            { partnersCards }
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(PartnersSection);
